import { Grid, Typography } from "@mui/material";
import { UICheckBox } from "../../../../components";
import { useTranslation } from "react-i18next";
import { nodos } from "../../../../../helpers";
import { EmployeeNavigation } from "../CreateGroups/EmployeeNavigation";

export const CheckBoxMenuEmployees = ({ formik }) => {
  const { t } = useTranslation(["sidenav"]);

  return (
    <>
      {EmployeeNavigation?.map((obj, index) => {
        if (obj?.tree) {
          return nodos(t, obj, index, formik);
        } else {
          return (
            <Grid key={index} container>
              <Grid item={true} xs={12}>
                <Grid container>
                  <Grid item={true} xs={12}>
                    <UICheckBox
                      label={t(`sidenav:${obj.name}`)}
                      name={`permits.${obj.path}.path`}
                      checked={formik.values?.permits[obj?.path]?.path || false}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Grid container>
                      <Grid item={true} xs={1}></Grid>
                      <Grid item={true} xs={11}>
                        <Grid container>
                          {obj?.permissions?.length > 0 && (
                            <Grid item={true} xs={12}>
                              <Typography variant="overline">
                                {t("sidenav:Permisos")}
                              </Typography>
                            </Grid>
                          )}
                          {obj?.permissions?.map((codename, index) => (
                            <Grid key={index} item={true} xs={12}>
                              <UICheckBox
                                label={t(`sidenav:${codename}`)}
                                name={`permits.${obj.path}.permissions.${codename}`}
                                checked={
                                  formik.values?.permits[obj?.path]
                                    ?.permissions[codename] || false
                                }
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }
      })}
    </>
  );
};
