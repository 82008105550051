import { Grid, Typography } from "@mui/material";
import { UICheckBox } from "components";
import { Fragment } from "react";

const especialCodenames = ["change_employee_number"];

export const translationsCodenames = (codename) => {
  if (especialCodenames.includes(codename)) {
    return `sidenav:${codename}`;
  }

  if (codename.includes("add_")) {
    return `sidenav:addPermission`;
  } else if (codename.includes("change_")) {
    return `sidenav:changePermission`;
  } else if (codename.includes("delete_")) {
    return `sidenav:deletePermission`;
  } else if (codename.includes("auto_approve_jobPositions")) {
    return `sidenav:auto_approve_jobPositions`;
  }
};

export const nodos = (t, nodo, index, formik) => {
  return (
    <Grid key={index} container>
      <Grid item={true} xs={12}>
        <UICheckBox
          label={t(`sidenav:${nodo?.name}`)}
          name={`permits.${nodo?.path}.path`}
          checked={formik.values?.permits[nodo?.path]?.path || false}
        />
      </Grid>
      <Grid item={true} xs={12}>
        {nodo?.tree?.map((routes, index) => {
          if (routes?.tree) {
            return (
              <Fragment key={index}>
                {formik.values?.permits[nodo?.path]?.path && (
                  <>
                    <Grid key={index} container>
                      <Grid item={true} xs={1}></Grid>
                      <Grid item={true} xs={11}>
                        {nodos(t, routes, index, formik)}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Fragment>
            );
          } else {
            return (
              <Fragment key={index}>
                {formik.values?.permits[nodo?.path]?.path && (
                  <Grid key={index} container>
                    <Grid item={true} xs={1}></Grid>
                    <Grid item={true} xs={11}>
                      <Grid container>
                        <Grid item={true} xs={12}>
                          <UICheckBox
                            label={t(`sidenav:${routes?.name}`)}
                            name={`permits.${routes?.path}.path`}
                            checked={
                              formik.values?.permits[routes?.path]?.path ||
                              false
                            }
                          />
                        </Grid>
                        {formik.values?.permits[routes?.path]?.path && (
                          <Grid item={true} xs={12}>
                            <Grid container>
                              <Grid item={true} xs={1}></Grid>
                              <Grid item={true} xs={11}>
                                <Grid container>
                                  {routes?.permissions?.length > 0 && (
                                    <Grid item={true} xs={12}>
                                      <Typography variant="overline">
                                        {t("sidenav:Permisos")}
                                      </Typography>
                                    </Grid>
                                  )}
                                  {routes?.permissions
                                    ?.filter(
                                      (permission) =>
                                        !permission.includes("view_")
                                    )
                                    ?.map((codename, index) => (
                                      <Grid key={index} item={true} xs={12}>
                                        <UICheckBox
                                          label={t(
                                            translationsCodenames(codename)
                                          )}
                                          name={`permits.${routes?.path}.permissions.${codename}`}
                                          checked={
                                            formik.values?.permits[routes?.path]
                                              ?.permissions[codename] || false
                                          }
                                        />
                                      </Grid>
                                    ))}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Fragment>
            );
          }
        })}
      </Grid>
    </Grid>
  );
};

export function generateInitialValues(navigationArray) {
  const initialValues = {};

  function processItem(item) {
    const { path, tree, permissions } = item;

    if (path) {
      initialValues[path] = { path: true, permissions: {} };

      if (permissions && permissions.length > 0) {
        permissions.forEach((permission) => {
          initialValues[path].permissions[permission] = true;
        });
      }

      if (tree && tree.length > 0) {
        tree.forEach((subItem) => {
          processItem(subItem);
        });
      }
    }
  }

  navigationArray.forEach((item) => {
    processItem(item);
  });

  Object.keys(initialValues).forEach((key) => {
    initialValues[key].path = false;
    if (initialValues[key].permissions) {
      Object.keys(initialValues[key].permissions).forEach((permission) => {
        if (permission.startsWith("view_")) {
          initialValues[key].permissions[permission] = true;
        } else {
          initialValues[key].permissions[permission] = false;
        }
      });
    }
  });

  return initialValues;
}

export function transformObjectForPermission(inputObj, app = null) {
  const transformedObj = {
    name:
      app === "organization"
        ? `Organization - ${inputObj?.name}`
        : `Employees - ${inputObj?.name}`,
    description: inputObj?.description,
    default: inputObj?.default,
    permits: [],
  };

  const permits = inputObj?.permits;

  for (const key in permits) {
    const path = key;
    const pathValue = permits[key]?.path;
    const permissions = Object.keys(permits[key]?.permissions)?.filter(
      (permission) => permits[key]?.permissions[permission] === true
    );
    if (pathValue === true) {
      transformedObj.permits.push({
        path: path,
        permissions: permissions,
      });
    }
  }

  if (inputObj?._id && inputObj?.tenant) {
    transformedObj._id = inputObj._id;
    transformedObj.tenant = inputObj.tenant;
  }

  return transformedObj;
}

export function reverseTransformObject(transformedObj, initial, codenames) {
  if (transformedObj !== null) {
    const inputObj = {
      _id: transformedObj?._id,
      tenant: transformedObj?.tenant,
      name: transformedObj?.name
        ?.replace("Organization - ", "")
        ?.replace("Employees - ", ""),
      description: transformedObj?.description,
      default: transformedObj?.default,
      permits: {},
    };

    transformedObj.permits.forEach((permit) => {
      const { path, permissions } = permit;
      inputObj.permits[path] = {
        path: true,
        permissions: {},
      };
      permissions.forEach((permission) => {
        const codename = codenames?.find(
          (c) => c?._id === permission
        )?.codename;
        inputObj.permits[path].permissions[codename] = true;
      });
    });

    const mergedPermissions = {};

    for (const key in initial.permits) {
      if (key in inputObj.permits) {
        const currentPermissions = inputObj.permits[key].permissions;
        const initialPermissions = initial.permits[key].permissions;
        mergedPermissions[key] = {
          path: inputObj.permits[key].path,
          permissions: { ...initialPermissions, ...currentPermissions },
        };
      } else {
        mergedPermissions[key] = { ...initial.permits[key] };
      }
    }

    for (const key in inputObj.permits) {
      if (!(key in initial.permits)) {
        mergedPermissions[key] = { ...inputObj.permits[key] };
      }
    }

    delete inputObj.permits;

    const retorno = { ...inputObj, permits: mergedPermissions };

    return retorno;
  } else {
    return null;
  }
}

export function replaceCodenames(transformedObj, permissions) {
  const codenameMap = {};
  permissions.forEach((permission) => {
    codenameMap[permission.codename] = permission._id;
  });

  transformedObj.permits.forEach((permit) => {
    permit.permissions = permit.permissions
      .filter((permission) => permission !== "undefined")
      .map((permission) => {
        if (permission in codenameMap) {
          return codenameMap[permission];
        } else {
          return permission;
        }
      });
  });

  return transformedObj;
}
