import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSocket, useActionsNotificatio } from "hooks";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { convertUTCToLocal } from "helpers";

export const NotificationsCenter = () => {
  const { t } = useTranslation(["general"]);

  const { markAsRead, deleteNotification, redirectNotification } =
    useActionsNotificatio();

  const { notifications, fetchNotifications } = useSocket();
  const { mainColor } = useSelector(parameterizationSelect);

  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        justifyContent: "center",
      }}
    >
      <Typography variant="h5" gutterBottom>
        {t("general:Centrodenotificaciones")}
      </Typography>
      <Paper
        sx={{
          width: 600,
          padding: "1rem",
          "@media (max-width: 600px)": {
            width: "100%",
            maxWidth: 400,
          },
        }}
      >
        {notifications?.map((notification) => (
          <Stack
            key={notification._id}
            direction="row"
            spacing={1}
            className="notifying"
            style={{
              padding: "6px 1rem",
              marginBottom: "6px",
              backgroundColor: notification?.isOpen
                ? "#f7f7fa"
                : `${mainColor}10`,
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                padding: "10px",
                cursor: "pointer",
                width: "100%",
              }}
              onClick={() => {
                if (!notification?.isOpen) {
                  markAsRead(notification, fetchNotifications);
                }
                redirectNotification(notification?.type);
              }}
            >
              <Typography
                variant="overline"
                display="block"
                gutterBottom
                sx={{ lineHeight: 2 }}
              >
                {convertUTCToLocal(notification.sendAt).format(
                  "YYYY-MM-DD HH:mm"
                )}
              </Typography>
              <Typography
                variant="overline"
                display="block"
                gutterBottom
                sx={{ lineHeight: 2 }}
              >
                {notification.msg}
              </Typography>
            </div>
            <div
              style={{
                height: "auto !important",
                display: "grid",
                placeItems: "center",
              }}
            >
              <IconButton
                color="primary"
                onClick={() => {
                  deleteNotification(notification, fetchNotifications);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </Stack>
        ))}
      </Paper>
    </Box>
  );
};
