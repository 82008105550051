import { useEffect, useState } from "react";
import { Box, Checkbox, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setDataForTable } from "@redux/slices/requests";
import { UIButton, UIModal } from "components";
import { CreateRequests } from "modules/Requests/CreateRequests";
import { setStaffRequest } from "@redux/slices/recruitment";

export const Filtro = ({ dataFilter, firstKey = null }) => {
  const { t } = useTranslation(["request"]);
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(firstKey);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (checked !== "staffRequests") {
      setChecked("staffRequests");
    }
  }, [dataFilter]);

  return (
    <>
      <Paper
        sx={{
          height: "100%",
        }}
      >
        <Box
          p={2}
          sx={{
            overflow: "hidden",
          }}
        >
          <UIButton
            label={t("request:CrearSolicitud")}
            onClick={() => {
              dispatch(
                setStaffRequest({
                  applicant: "",
                  vacancyReason: [],
                  vacancies: 1,
                  jobPosition: "",
                })
              );
              setOpen(true);
            }}
          />
          <hr />
          <Typography variant="overline" display="block" gutterBottom>
            {t("request:filtro")}
          </Typography>
          {Object.keys(dataFilter).map((key) => (
            <Box
              key={key}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Checkbox
                checked={checked === key}
                onChange={() => {
                  setChecked(key);
                  dispatch(setDataForTable(dataFilter[key]));
                }}
              />
              <Typography
                sx={{
                  margin: "auto 0",
                  textOverflow: "ellipsis !important",
                  lineHeight: "1.5",
                }}
                variant="overline"
              >
                {t(`request:${key.toUpperCase()}`)}
              </Typography>
            </Box>
          ))}
        </Box>
      </Paper>

      <UIModal open={open} setOpen={setOpen} width={"90vw"}>
        <Typography variant="h5" display="block" gutterBottom>
          {t("request:CrearSolicitud")}
        </Typography>
        <hr />
        <CreateRequests setOpen={setOpen} />
      </UIModal>
    </>
  );
};
