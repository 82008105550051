import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Tab, Tabs } from "@mui/material";

export const MenuRequests = ({ ubication }) => {
  const { t } = useTranslation(["request"]);
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ width: "100%", display: "grid", placeItems: "center" }}>
        <Tabs
          value={ubication}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          style={{ maxWidth: "100%" }}
        >
          <Tab
            value="Request"
            label={t("request:MyRequests")}
            onClick={() => {
              navigate("/cereza-organization/requests");
            }}
          />
          <Tab
            value="Signature"
            label={t("request:Signature")}
            onClick={() => {
              navigate("/cereza-organization/requests/signature");
            }}
          />
          <Tab
            value="CreateRequests"
            label={t("request:RequestsHistory")}
            onClick={() => {
              navigate("/cereza-organization/requests/history");
            }}
          />
        </Tabs>
      </Box>
    </>
  );
};
