import { cerezaClient } from "../adapters";

const VACANCY = "/v1/rec/settings";
const SIGNS = "/v1/org/settings/signs";
const STAFF_REQUEST = "/v1/rec/jobs/staff-request";
const VACANCY_REASONS = "/v1/rec/jobs/vacancy-reasons";
const HISTORY = "/v1/rec/history/staff-request";
const APPROVED = "/v1/rec/staff-request/approved";
const VACANCY_PUBLICAR = "/v1/rec/jobs/vacancy";

export function getVacancy() {
  return cerezaClient.get(`${VACANCY}`);
}

export function patchVacancy(values) {
  return cerezaClient.patch(`${VACANCY}/${values._id}`, values);
}

export function getVacancyOthersSttings() {
  return cerezaClient.get(`${SIGNS}`);
}

export function patchVacancyOthersSttings(values) {
  return cerezaClient.put(`${SIGNS}/${values._id}`, values);
}

export function getStaffRequest(id) {
  if (id) {
    return cerezaClient.get(`${STAFF_REQUEST}/${id}`);
  } else {
    return cerezaClient.get(`${STAFF_REQUEST}`);
  }
}

export function postStaffRequest(data) {
  return cerezaClient.post(`${STAFF_REQUEST}`, data);
}

export function putStaffRequest(data) {
  return cerezaClient.put(`${STAFF_REQUEST}/${data._id}`, data);
}

export function deleteStaffRequest(data) {
  return cerezaClient.delete(`${STAFF_REQUEST}/${data._id}`);
}

export function getVacancyReasons(id) {
  if (id) {
    return cerezaClient.get(`${VACANCY_REASONS}/${id}`);
  } else {
    return cerezaClient.get(`${VACANCY_REASONS}`);
  }
}

export function postVacancyReasons(data) {
  return cerezaClient.post(`${VACANCY_REASONS}`, data);
}

export function putVacancyReasons(data) {
  return cerezaClient.put(`${VACANCY_REASONS}/${data._id}`, data);
}

export function deleteVacancyReasons(data) {
  return cerezaClient.delete(`${VACANCY_REASONS}/${data._id}`);
}

export function getHistoryStaff() {
  return cerezaClient.get(`${HISTORY}`);
}

export function getVacancies(id) {
  if (id) {
    return cerezaClient.get(`${VACANCY_PUBLICAR}/${id}`);
  } else {
    return cerezaClient.get(VACANCY_PUBLICAR);
  }
}

export function postVacancies(data) {
  return cerezaClient.post(VACANCY_PUBLICAR, data);
}

export function putVacancies(data) {
  return cerezaClient.put(VACANCY_PUBLICAR, data);
}

export function getApprovedStaffs() {
  return cerezaClient.get(APPROVED);
}
