import { useState } from "react";
import { UIModal } from "components";
import { JustifyForm } from "../../../ShiftAttendance/AttendanceReports/JustifyForm";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { StatusDot } from "../../components/StatusDot";

export const JustifyModal = ({
  openJustification,
  setOpenJustification,
  justificationRequest,
  employees,
}) => {
  const { t } = useTranslation(["request"]["general"]);

  const [tabs, setTabs] = useState("Form");

  return (
    <>
      <UIModal
        open={openJustification}
        setOpen={setOpenJustification}
        width={"70%"}
      >
        <Box sx={{ width: "100%", display: "grid", placeItems: "center" }}>
          <Tabs
            value={tabs}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            style={{ maxWidth: "100%" }}
          >
            <Tab
              value="Form"
              label={t("request:Form")}
              onClick={() => {
                setTabs("Form");
              }}
            />
            {justificationRequest?.signStatus && (
              <Tab
                value="SignStatus"
                label={t("request:SignStatus")}
                onClick={() => {
                  setTabs("SignStatus");
                }}
              />
            )}
          </Tabs>
        </Box>

        <br />
        {tabs === "Form" && (
          <JustifyForm justificationRequest={justificationRequest} />
        )}

        {tabs === "SignStatus" && (
          <>
            {employees ? (
              <Box
                sx={{
                  width: "100%",
                  display: "grid",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                {Object.keys(justificationRequest?.signStatus).map((key) => (
                  <Box
                    key={key}
                    sx={{ padding: "1rem", border: "1px solid #c5c6c7" }}
                  >
                    <Typography variant="overline" display="block" gutterBottom>
                      {
                        <StatusDot
                          status={justificationRequest?.signStatus[key]?.status}
                        />
                      }
                      {" - "}
                      {employees[key]}
                    </Typography>
                    {justificationRequest?.signStatus[key]?.msg !== null && (
                      <>
                        <hr />
                        <Alert severity="info">
                          <AlertTitle>{t("general:Mensaje")}</AlertTitle>
                          {justificationRequest?.signStatus[key]?.msg}
                        </Alert>
                      </>
                    )}
                  </Box>
                ))}
              </Box>
            ) : (
              <Box
                p={2}
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </>
        )}
      </UIModal>
    </>
  );
};
