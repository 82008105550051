import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UITextField } from "components";

export const FormVacancyReason = ({ formik }) => {
  const { t } = useTranslation(["recruitment"]["general"]);

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12}>
        <UITextField
          formik={formik}
          name={"name"}
          label={t("recruitment:Name")}
          disabled={formik.isSubmitting}
        />
      </Grid>
    </Grid>
  );
};
