import Grid from "@mui/material/Unstable_Grid2/Grid2";
import SelectingTable from "components/Table/SelectingTable";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setJobDescription } from "@redux/slices/jobDescription";
import { CircularProgress } from "@mui/material";
import { Stack } from "rsuite";
import { getDepartments, getJobPositions } from "api";
import { generalSelect, setSkills } from "@redux/slices/general";
import { getSkills } from "api";
import { useSelector } from "react-redux";

export const JobsDescription = () => {
  const { t } = useTranslation(["employee"]["sidenav"]);
  const [ended, setEnded] = useState(false);
  const [rows, setRows] = useState([]);
  const [reponseData, setResponseData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [departments, setDepartments] = useState(null);
  const [jobs, setJobs] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { skills } = useSelector(generalSelect);

  useEffect(() => {
    const list = async () => {
      try {
        const { data } = await getJobPositions(null);
        setJobs(data.reduce((res, i) => ((res[i._id] = i.title), res), {}));
      } catch (e) {
        console.log(e);
      }
    };

    list();
  }, []);

  useEffect(() => {
    if (skills?.length === 0 || !skills || skills === undefined) {
      try {
        getSkills("selectors").then((res) => {
          dispatch(setSkills(res.data));
        });
      } catch (err) {
        console.error("Error getting skills:", err);
      }
    }
  }, []);

  const headCells = [
    {
      id: "title",
      label: t("employee:Position"),
      numeric: false,
      disablePadding: true,
    },
    {
      id: "department",
      label: t("employee:Department"),
      numeric: false,
      disablePadding: true,
    },
    {
      id: "reportAt",
      label: t("employee:ReportAt"),
      numeric: false,
      disablePadding: true,
    },
    {
      id: "numberJobs",
      label: t("employee:NumeroPuestos"),
      numeric: false,
      disablePadding: true,
    },
  ];

  useEffect(() => {
    const aDepartments = async () => {
      const { data } = await getDepartments();
      setDepartments(data.reduce((res, i) => ((res[i._id] = i.name), res), {}));
    };

    try {
      aDepartments();
    } catch (e) {
      console.log("Error get deparments: ", e);
    }
  }, []);

  useEffect(() => {
    const list = async () => {
      try {
        const { data } = await getJobPositions(null);
        setResponseData(data);
        setRows(
          data.map((e) => {
            const reportAt = data.find((r) => r._id === e.reportAt);
            return {
              id: e._id,
              title: e.title,
              department: departments[e.department],
              reportAt: e.reportAt ? reportAt.title : "",
              numberJobs: e.quantity,
            };
          })
        );
        setEnded(true);
      } catch (e) {
        console.log(e);
      }
    };
    if (departments && jobs) {
      list();
    }
  }, [departments, jobs]);

  const actionOnClick = async (row) => {
    try {
      let data = reponseData.find((e) => row.id === e._id);
      toJobDescription(data);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const toJobDescription = (data) => {
    dispatch(setJobDescription(data));
    navigate("/cereza-organization/organization/job-description");
  };

  return (
    <Grid container={true}>
      <Grid item={"true"} xs={12} md={12}>
        {ended ? (
          <SelectingTable
            headCells={headCells}
            rows={rows}
            selected={selected}
            setSelected={setSelected}
            name={t("sidenav:JobsDescription")}
            actionOnClick={actionOnClick}
          />
        ) : (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <CircularProgress />
          </Stack>
        )}
      </Grid>
      <Grid item={"true"} xs={12} md={9}></Grid>
    </Grid>
  );
};
