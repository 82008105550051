import {
  CalendarWidget,
  Coworkers,
  DarkMode,
  UpcomingHolidays,
  Weather,
} from "./components";
import "../styles.css";

export const Dashboard = () => {
  return (
    <>
      <div className="gridWidgets">
        <div className="colegas">
          <Coworkers />
        </div>
        <div className="calendar">
          <CalendarWidget />
        </div>
        <div className="holiday">
          <UpcomingHolidays />
        </div>
        <div className="clima">
          <Weather />
        </div>
        <div className="darkMode">
          <DarkMode />
        </div>
      </div>
    </>
  );
};
