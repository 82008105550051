import { UIPaper } from "../../../components";
import { MenuNav } from "../MenuNav";
import { Box } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useTranslation } from "react-i18next";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { MailSettings } from "../Templates/MailSettings";
import { Email as EmailIcon } from "@mui/icons-material";
import { useAccess } from "../../../../hooks";
import { Paneles } from "./Paneles";
import { GridButtons } from "./GridButtons";

export const Configurations = () => {
  const { access } = useAccess();
  const { t } = useTranslation(["settings"]["general"]);

  const organizationBase =
    "/cereza-organization/organization/organization-settings";

  const organization = [{
    seccion: "organization-settings",
    hasAccess: access(`${organizationBase}`),
    navigate: `${organizationBase}`,
    label: t("organization:organization"),
  }, {
    seccion: "ubications-org",
    hasAccess: access(`${organizationBase}/ubications-org`),
    navigate: `${organizationBase}/ubications-org`,
    label: t("organization:locations"),
  }, {
    seccion: "catalogs",
    hasAccess: access(
      `${organizationBase}/catalogs`,
      `${organizationBase}/catalogs-currencies`,
      `${organizationBase}/catalogs-languages`,
    ),
    navigate: `${organizationBase}/catalogs`,
    label: t("organization:catalogs"),
  }, {
    seccion: "plan",
    hasAccess: access(`${organizationBase}/plan`),
    navigate: `${organizationBase}/plan`,
    label: t("organization:plan"),
  }];

  const shiftAssitanceBase = "/cereza-organization/shift-attendance";

  const shiftAssitance = [{
    seccion: "polices",
    hasAccess: access(`${shiftAssitanceBase}/polices`),
    navigate: `${shiftAssitanceBase}/polices`,
    label: t("sidenav:Polices"),
  }, {
    seccion: "work-schedules",
    hasAccess: access(`${shiftAssitanceBase}/work-schedules`),
    navigate: `${shiftAssitanceBase}/work-schedules`,
    label: t("sidenav:HorarioDeTurnoDeTrabajoDeCereza"),
  }, {
    seccion: "shift-attendance-settings",
    hasAccess: access(`${shiftAssitanceBase}/shift-attendance-settings`),
    navigate: `${shiftAssitanceBase}/shift-attendance-settings`,
    label: t("sidenav:Ajustes"),
  }];

  const paneles = [{
    id: "email",
    title: t("settings:Email"),
    icon: <EmailIcon />,
    content: <MailSettings />,
    hasAccess: access("/cereza-organization/Configuraciones"),
  }, {
    id: "organization",
    title: t("settings:Organización"),
    icon: <ApartmentIcon />,
    content: <GridButtons options={organization} />,
    hasAccess: access("/cereza-organization/organization/organization-configurations"),
  }, {
    id: "turno",
    title: t("settings:Turno"),
    icon: <QueryBuilderIcon />,
    content: <GridButtons options={shiftAssitance} />,
    hasAccess: access("/cereza-organization/TurnoDeAsistencia"),
  }];

  return (
    <UIPaper>
      <MenuNav ubication={"Ajustes"} />
      <Box p={2}>
        <Paneles paneles={paneles} />
      </Box>
    </UIPaper>
  );
};
