import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { UIModal, UIButton, BasicTable, Toast } from "components";
import { Stack, TableCell, Box, CircularProgress } from "@mui/material";
import { authUserSelect } from "@redux/slices/authUser";
import { patchDocument } from "api";
import { managerErrors } from "utils/functions";
import { useRequests } from "hooks";
import { convertUTCToLocal } from "helpers";
import { VacationRequest } from "../../CreateRequests/VacationRequest";
import { TypesOfPermits } from "../../CreateRequests/TypesOfPermits";

import Swal from "sweetalert2";

export const PermitsDocument = ({ documents, setExpanded }) => {
  const { t } = useTranslation(["request"]["recruitment"]);
  const { t: tG } = useTranslation(["general"]);

  const { user } = useSelector(authUserSelect);

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [permit, setPermit] = useState(null);
  const [permitsRows, setPermitsRows] = useState([]);

  const { employees } = useRequests();

  useEffect(() => {
    const list = async () => {
      try {
        setPermitsRows(
          documents?.documents?.attendancePermits.map((obj) => ({
            id: obj?._id,
            folio: obj?.folio,
            applicant: employees[obj?.applicant],
            startDate: convertUTCToLocal(obj?.startDate).format(
              "YYYY-MM-DD HH:mm"
            ),
            endDate: convertUTCToLocal(obj?.endDate).format("YYYY-MM-DD HH:mm"),
            type: t(`request:${obj?.type}`),
            status: t(`recruitment:${obj?.status}`),
          }))
        );
      } catch (e) {
        console.log(e);
      }
    };

    if (employees) {
      list();
    }
  }, [employees]);

  const handleClick = (row) => {
    let data = documents?.documents?.attendancePermits.find(
      (s) => row?.id === s?._id
    );
    setPermit(data);
    setOpen(true);
  };

  const onSubmit = async (type, reason = null) => {
    try {
      setIsSubmitting(true);
      const data = documents?.signAuths?.[permit?._id];
      const payloadFunction = async () => {
        if (type === "aprobar") {
          return {
            _id: data?._id,
            signer: user._id,
            isApproved: true,
            document: data?.document,
          };
        } else if (type === "doChange") {
          return {
            _id: data?._id,
            signer: user._id,
            isApproved: false,
            document: data?.document,
            reject: {
              type: "TEMPORAL",
              reason: reason,
            },
          };
        } else if (type === "rechazar") {
          return {
            _id: data?._id,
            signer: user._id,
            isApproved: false,
            document: data?.document,
            reject: {
              type: "FINAL",
              reason: reason,
            },
          };
        }
      };

      const payload = await payloadFunction();
      await patchDocument(payload);
      setOpen(false);
      Toast.fire({
        icon: "success",
        title: tG("general:AcciónExitosa"),
      });
      setExpanded(Math.random());
      setTimeout(() => window.location.reload(), 1500);
    } catch (error) {
      setIsSubmitting(false);
      managerErrors(error?.response?.data?.message);
      console.error(error);
    }
  };

  return (
    <>
      {permitsRows.length > 0 ? (
        <BasicTable rows={permitsRows} handleClick={handleClick}>
          <TableCell>{t("recruitment:Folio")}</TableCell>
          <TableCell>{t("recruitment:Applicant")}</TableCell>
          <TableCell>{t("recruitment:startDate")}</TableCell>
          <TableCell>{t("recruitment:endDate")}</TableCell>
          <TableCell>{t("recruitment:type")}</TableCell>
          <TableCell>{t("recruitment:status")}</TableCell>
        </BasicTable>
      ) : (
        <Box
          p={2}
          sx={{
            width: "100%",
            height: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <UIModal open={open} setOpen={setOpen} width={"90%"}>
        {permit?.type === "VACATION" ? (
          <VacationRequest request={permit} btnsActions={false} />
        ) : (
          <TypesOfPermits request={permit} btnsActions={false} />
        )}

        <br />
        <Stack
          mt={2}
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 1, md: 1 }}
        >
          <UIButton
            label={t("request:aprobar")}
            variant="contained"
            onClick={() => onSubmit("aprobar")}
            loading={isSubmitting}
            sx={{}}
          />
          <UIButton
            label={t("request:solicitarCambio")}
            variant="contained"
            onClick={() => {
              setOpen(false);
              Swal.fire({
                input: "textarea",
                inputLabel: t("request:Message"),
                inputPlaceholder: t("request:Typeyourmessagehere"),
                confirmButtonText: t("request:enviarMessage"),
                cancelButtonText: t("request:cancelar"),
                inputAttributes: {
                  "aria-label": t("request:Typeyourmessagehere"),
                },
                showCancelButton: true,
              }).then((result) => {
                if (result?.isConfirmed) {
                  onSubmit("doChange", result?.value);
                }
              });
            }}
            loading={isSubmitting}
            sx={{}}
          />
          <UIButton
            label={t("request:rechazar")}
            variant="contained"
            onClick={() => {
              setOpen(false);
              Swal.fire({
                input: "textarea",
                inputLabel: t("request:Message"),
                inputPlaceholder: t("request:Typeyourmessagehere"),
                confirmButtonText: t("request:enviarMessage"),
                cancelButtonText: t("request:cancelar"),
                inputAttributes: {
                  "aria-label": t("request:Typeyourmessagehere"),
                },
                showCancelButton: true,
              }).then((result) => {
                const message = result?.value;
                if (result?.isConfirmed) {
                  Swal.fire({
                    title: t("general:Are"),
                    text: t("general:Youwon"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: t("request:siRechazalo"),
                    cancelButtonText: t("request:cancelar"),
                  }).then((result) => {
                    if (result?.isConfirmed) {
                      onSubmit("rechazar", message);
                    }
                  });
                }
              });
            }}
            loading={isSubmitting}
            sx={{}}
          />
        </Stack>
      </UIModal>
    </>
  );
};
