import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { UISelectFast, UITextFast } from "components";
import { useTranslation } from "react-i18next";
import { getJobPositions } from "api";
import { JobDescriptionDisabled } from "./JobDescriptionDisabled";

export const FormJobPosition = ({
  formik,
  departments,
  canAdd,
  canChange,
}) => {
  const { t } = useTranslation(["employee"]["general"]);
  const workMode = [
    { value: "ON_SITE", label: t("employee:OnSite") },
    { value: "REMOTE", label: t("employee:Remote") },
    { value: "HYBRID", label: t("employee:Hybrid") },
  ];
  const publicationLanguages = [
    { value: "es", label: "Español" },
    { value: "en", label: "English" },
    { value: "fr", label: "Français" },
    { value: "pt", label: "Português" },
  ];
  const positionType = [
    { value: "ADMINISTRATIVE", label: t("recruitment:ADMINISTRATIVE") },
    { value: "OPERATIONAL", label: t("recruitment:OPERATIONAL") },
    { value: "MANAGERIAL", label: t("recruitment:MANAGERIAL") },
  ];

  const disabled = JobDescriptionDisabled({ canAdd, canChange, formik });

  const [jobs, setJobs] = useState([]);
  const [jobsDowloaded, setJobsDowloaded] = useState(false);

  useEffect(() => {
    const list = async () => {
      try {
        const { data } = await getJobPositions(null);

        const jobs = data
          .map(({ _id, title }) => {
            return { value: _id, label: title };
          })
          .filter((obj) => obj?.label !== formik?.values?.title);

        setJobs(jobs);

        setJobsDowloaded(true);
      } catch (e) {
        console.log(e);
      }
    };

    list();
  }, []);

  return (
    jobsDowloaded && (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} md={3}>
          <UITextFast
            label={t("employee:Position")}
            name={"title"}
            disabled={disabled}
            required={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UISelectFast
            label={t("employee:Department")}
            name="department"
            options={departments}
            disabled={disabled}
            required={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UISelectFast
            label={t("employee:ReportAt")}
            name="reportAt"
            options={jobs}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UISelectFast
            label={t("employee:WorkModality")}
            name="workMode"
            options={workMode}
            disabled={disabled}
            required={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UISelectFast
            label={t("recruitment:PositionType")}
            name="positionType"
            options={positionType}
            disabled={disabled}
            required={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={2}>
          <UITextFast
            type={"number"}
            label={t("employee:NumeroPuestos")}
            name={"quantity"}
            disabled={disabled ||
              ["APPROVED", "INACTIVE"].includes(formik.values?.status)}
            required={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UISelectFast
            label={t("recruitment:publicationLanguage")}
            name="publicationLanguage"
            options={publicationLanguages}
            disabled={disabled}
            required={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={12}>
          <UITextFast
            label={t("employee:ExternalRelations")}
            name="externalRelations"
            disabled={disabled}
            required={false}
          />
        </Grid>
      </Grid>
    )
  );
};
