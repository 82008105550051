import { useDispatch, useSelector } from "react-redux";
import { getSchedule } from "api";
import {
  setAllWorkSchedules,
  workSchedulesSelect,
} from "@redux/slices/work-schedules";

export function useWorkSchedules() {
  const dispatcher = useDispatch();
  const { allWorkSchedules } = useSelector(workSchedulesSelect);

  const refreshWorkSchedules = async (refresh = false) => {
    if (
      allWorkSchedules?.length === 0 ||
      !allWorkSchedules ||
      allWorkSchedules === undefined ||
      refresh
    ) {
      try {
        const { data } = await getSchedule(null);
        dispatcher(setAllWorkSchedules(data));
      } catch (err) {
        console.error("Error getting departments:", err);
      }
    }
  };

  return { refreshWorkSchedules };
}
