import { TextField } from "@mui/material";
import { FastField, useField } from "formik";

export const UITextFast = (props) => {
  const { name, ...rest } = props;
  const [field] = useField(name);

  return (
    <FastField {...field} {...rest}>
      {({ field, form: { touched, errors } }) => (
        <>
          <TextField
            sx={{
              width: "100%",
            }}
            {...field}
            {...rest}
          />
          {touched[field.name] &&
            errors[field.name] && <div>{errors[field.name]}</div>}
        </>
      )}
    </FastField>
  );
};
