export const JobDescriptionDisabled = ({ canAdd, canChange, formik }) => {
  const { status, _id } = formik.values;
  const bool_id = Boolean(_id);

  return status === "PENDING" || (!canAdd && status === "REVIEW_REQUIRED") ||
    (!canChange && bool_id && ["APPROVED", "INACTIVE"].includes(status)) ||
    formik.isSubmitting;
};

export const JobDescriptionEnabledButtons = (
  { canAdd, canChange, formik },
) => {
  const { status } = formik.values;

  return (canAdd && status === "REVIEW_REQUIRED") ||
    (canChange && ["APPROVED", "INACTIVE"].includes(status));
};
