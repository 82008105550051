import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

export const StatusDot = ({ status }) => {
  const { t } = useTranslation(["recruitment"]);

  const statusColor = () => {
    if (status === "PENDING") {
      //! Amarillo
      return "#ffc107";
    } else if (status === "REVIEW_REQUIRED") {
      //! Azul
      return "#2979ff";
    } else if (status === "REJECTED") {
      //! Rojo
      return "#ff1744";
    } else if (status === "APPROVED") {
      //! Verde
      return "#76ff03";
    }
  };

  return (
    <Chip
      label={t(`recruitment:${status}`)}
      variant="outlined"
      style={{
        border: `1px solid ${statusColor()}`,
        color: statusColor(),
      }}
    />
  );
};
