import { useAccess } from "../../../../hooks";
import { UIPaper } from "../../../components";
import { MenuNav } from "../MenuNav";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AssignmentInd as AssignmentIndIcon } from "@mui/icons-material";
import { ModalImportEmployees } from "../../../modules/Employees/Profiles/ModalImportEmployees";
import { useState } from "react";
import { Paneles } from "../Configurations/Paneles";
import { GridButtons } from "../Configurations/GridButtons";

export const Import = () => {
  const { HaveAcessToCreate } = useAccess();
  const { t } = useTranslation(["settings"]["general"]);
  const [openImportEmployee, setOpenImportEmployee] = useState(false);

  const employee = [{
    seccion: "employees-profiles",
    hasAccess: HaveAcessToCreate("/cereza-organization/employees/profiles", [
      "add_employees",
    ]),
    navigate: `/cereza-organization/employees/profiles`,
    label: t("employee:ImportEmployees"),
    action: () => {
      setOpenImportEmployee(true);
    },
  }];

  const paneles = [{
    id: "employees",
    title: t("employee:ImportEmployees"),
    icon: <AssignmentIndIcon />,
    content: <GridButtons options={employee} />,
    hasAccess: true,
  }];

  return (
    <UIPaper>
      <MenuNav ubication={"ImportExport"} />
      <Box p={2}>
        <Paneles paneles={paneles} />
      </Box>
      <ModalImportEmployees
        open={openImportEmployee}
        setOpen={setOpenImportEmployee}
      />
    </UIPaper>
  );
};
