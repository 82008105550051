import { useEffect, useState } from "react";
import { MenuRequests } from "../MenuRequests";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getDocumentsToSign } from "api";
import { authUserSelect } from "@redux/slices/authUser";
import { useSelector } from "react-redux";
import { StaffDocument } from "../components/StaffDocument";
import { PermitsDocument } from "../components/PermitsDocument";
import { JustyficationsDocument } from "../components/JustyficationsDocument/JustyficationsDocument";
import { PositionsDocument } from "../components/PositionsDocument";

export const Signature = () => {
  const { t } = useTranslation(["request"]);

  const { user } = useSelector(authUserSelect);

  const [expanded, setExpanded] = useState(false);
  const [Requests, setStaffRequests] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const fetchGet = async () => {
      try {
        const { data } = await getDocumentsToSign(user._id);
        console.log("data: ", data);
        setStaffRequests(data);
      } catch (error) {
        console.error("getDocumentsToSign: ", error);
      }
    };
    fetchGet();
  }, []);

  return (
    <>
      <MenuRequests ubication={"Signature"} />
      <br />
      <Box sx={{ width: { xs: "90%", sm: "70%" }, margin: "0 auto" }}>
        {Requests?.documents?.staffRequests?.length > 0 && (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {t("request:STAFFREQUESTS")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <StaffDocument documents={Requests} setExpanded={setExpanded} />
            </AccordionDetails>
          </Accordion>
        )}

        {Requests?.documents?.attendancePermits?.length > 0 && (
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {t("request:SolicitudesdePermisosySolicitudesdeVacaciones")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PermitsDocument documents={Requests} setExpanded={setExpanded} />
            </AccordionDetails>
          </Accordion>
        )}

        {Requests?.documents?.justifications?.length > 0 && (
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {t("request:JUSTIFYREQUEST")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <JustyficationsDocument
                documents={Requests}
                setExpanded={setExpanded}
              />
            </AccordionDetails>
          </Accordion>
        )}

        {Requests?.documents?.jobPositions?.length > 0 && (
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {t("request:JOBPOSITIONSREQUEST")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PositionsDocument
                documents={Requests}
                setExpanded={setExpanded}
              />
            </AccordionDetails>
          </Accordion>
        )}

        {(Requests?.documents?.staffRequests === undefined ||
          Requests?.documents?.staffRequests?.length === 0) &&
          (Requests?.documents?.attendancePermits === undefined ||
            Requests?.documents?.attendancePermits?.length === 0) &&
          (Requests?.documents?.justifications === undefined ||
            Requests?.documents?.justifications?.length === 0) && (
            <Alert severity="info">
              <AlertTitle>{t("request:InfoTitle")}</AlertTitle>
              {t("request:InfoAlert")}
            </Alert>
          )}
      </Box>
    </>
  );
};
