import { UIPaper } from "../../../components";
import { useEffect } from "react";
import { useAccess } from "../../../../hooks";
import { useNavigate } from "react-router-dom";

export const RedirectAccessSettings = () => {
  const navigate = useNavigate();
  const { access } = useAccess();

  useEffect(() => {
    if (access("/cereza-organization/settings/configurations")) {
      navigate("/cereza-organization/settings/configurations");
    } else if (access("/cereza-organization/settings/templates")) {
      navigate("/cereza-organization/settings/templates");
    } else if (access("/cereza-organization/settings/import-export")) {
      navigate("/cereza-organization/settings/import-export");
    } else if (access("/cereza-organization/settings/security")) {
      navigate("/cereza-organization/settings/security");
    }
  }, []);

  return <UIPaper></UIPaper>;
};
