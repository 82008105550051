import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const JobDescriptionValidations = () => {
  const { t } = useTranslation();

  let salaryRangeSchema = Yup.object().shape(
    {
      minSalary: Yup.number()
        .transform((v, o) => (o === "" ? null : v))
        .nullable(true)
        .typeError(
          `${t("recruitment:DescriptionMinSalary")}, ${t("recruitment:NaN")}`,
        )
        .when(["maxSalary", "currency"], {
          is: (maxSalary, currency) => maxSalary || currency,
          then: (schema) =>
            schema
              .required(
                `${t("recruitment:DescriptionMinSalary")}, ${
                  t(
                    "recruitment:isRequired",
                  )
                }`,
              )
              .integer(
                `${t("recruitment:DescriptionMinSalary")}, ${
                  t(
                    "recruitment:OnlyIntegers",
                  )
                }`,
              )
              .min(0, (context) => {
                return `${t("recruitment:DescriptionMinSalary")}, ${
                  t(
                    "recruitment:GreaterToEqual",
                  )
                } ${context.min}`;
              }),
        }),
      maxSalary: Yup.number()
        .transform((v, o) => (o === "" ? null : v))
        .nullable(true)
        .typeError(
          `${t("recruitment:DescriptionMaxSalary")}, ${t("recruitment:NaN")}`,
        )
        .when(["minSalary", "currency"], {
          is: (minSalary, currency) => minSalary || currency,
          then: (schema) =>
            schema
              .required(
                `${t("recruitment:DescriptionMaxSalary")}, ${
                  t(
                    "recruitment:isRequired",
                  )
                }`,
              )
              .integer(
                `${t("recruitment:DescriptionMaxSalary")}, ${
                  t(
                    "recruitment:OnlyIntegers",
                  )
                }`,
              )
              .min(
                Yup.ref("minSalary"),
                (context) =>
                  `${t("recruitment:DescriptionMaxSalary")}, ${
                    t(
                      "recruitment:GreaterToEqual",
                    )
                  } ${context.min}`,
              ),
        }),
      currency: Yup.string().when(["minSalary", "maxSalary"], {
        is: (minSalary, maxSalary) => minSalary || maxSalary,
        then: (schema) =>
          schema.required(
            `${t("recruitment:DescriptionCurrency")}, ${
              t(
                "recruitment:isRequired",
              )
            }`,
          ),
      }),
    },
    [
      ["maxSalary", "currency"],
      ["minSalary", "currency"],
      ["minSalary", "maxSalary"],
    ],
  );

  const profileEducationShape = Yup.array().of(
    Yup.object().shape({
      education: Yup.string().required(
        `${t("recruitment:ProfileEducationEducation")}, ${
          t(
            "recruitment:isRequired",
          )
        }`,
      ),
      level: Yup.string().required(
        `${t("recruitment:ProfileEducationLevel")}, ${
          t(
            "recruitment:isRequired",
          )
        }`,
      ),
      status: Yup.string().required(
        `${t("recruitment:ProfileEducationStatus")}, ${
          t(
            "recruitment:isRequired",
          )
        }`,
      ),
    }),
  );

  const profileLanguagesShape = Yup.array().of(
    Yup.object().shape({
      language: Yup.string().required(
        `${"recruitment:ProfileLanguagesLanguage"}, ${
          t(
            "recruitment:isRequired",
          )
        }`,
      ),
      language: Yup.string().required(
        `${"recruitment:ProfileLanguagesFluencyLevel"}, ${
          t(
            "recruitment:isRequired",
          )
        }`,
      ),
    }),
  );

  const profileSkillShape = Yup.array().of(
    Yup.object().shape({
      skill: Yup.string().required(
        `${t("recruitment:ProfileSkillsSkill")}, ${
          t("recruitment:isRequired")
        }`,
      ),
      level: Yup.string().required(
        `${"recruitment:ProfileSkillsLevel"}, ${t("recruitment:isRequired")}`,
      ),
    }),
  );

  const jobPositionSchema = Yup.object().shape({
    quantity: Yup.number()
      .typeError(`${t("employee:quantity")}, ${t("recruitment:NaN")}`)
      .min(1, (context) => {
        return `${t("employee:NumeroPuestos")}, ${
          t(
            "recruitment:GreaterToEqual",
          )
        } ${context.min}`;
      })
      .integer(
        `${t("employee:NumeroPuestos")}, ${t("recruitment:OnlyIntegers")}`,
      ),
    description: Yup.object().shape({
      salaryRange: salaryRangeSchema,
    }),
    profile: Yup.object().shape({
      gender: Yup.string().required(
        `${t("recruitment:ProfileGender")}, ${t("recruitment:isRequired")}`,
      ),
      maritalStatus: Yup.string().required(
        `${t("recruitment:ProfileMaritalStatus")}, ${
          t(
            "recruitment:isRequired",
          )
        }`,
      ),
      minAge: Yup.number()
        .required(
          `${t("recruitment:ProfileMinAge")}, ${t("recruitment:isRequired")}`,
        )
        .typeError(`${t("recruitment:ProfileMinAge")}, ${t("recruitment:NaN")}`)
        .min(0, (context) => {
          return `${t("recruitment:ProfileMinAge")}, ${
            t(
              "recruitment:GreaterToEqual",
            )
          } ${context.min}`;
        })
        .max(99, (context) => {
          return `${t("recruitment:ProfileMinAge")}, ${
            t(
              "recruitment:LessToEqual",
            )
          } ${context.max}`;
        })
        .integer(
          `${t("recruitment:ProfileMinAge")}, ${t("recruitment:OnlyIntegers")}`,
        ),
      maxAge: Yup.number()
        .required(
          `${t("recruitment:ProfileMaxAge")}, ${t("recruitment:isRequired")}`,
        )
        .typeError(`${t("recruitment:ProfileMaxAge")}, ${t("recruitment:NaN")}`)
        .integer(
          `${t("recruitment:ProfileMaxAge")}, ${t("recruitment:OnlyIntegers")}`,
        )
        .max(99, (context) => {
          return `${t("recruitment:ProfileMaxAge")}, ${
            t(
              "recruitment:LessToEqual",
            )
          } ${context.max}`;
        })
        .when("minAge", (minAge, schema) => {
          return schema.test({
            test: (maxAge) => !!minAge && maxAge > minAge,
            message: `${t("recruitment:ProfileMaxAge")}, ${
              t(
                "recruitment:GreaterToEqual",
              )
            } ${minAge}`,
          });
        }),
      experience: Yup.object().shape({
        howManyYearsOfExperience: Yup.number()
          .typeError(
            `${t("recruitment:ProfileHowManyYearsOfExperience")}, ${
              t(
                "recruitment:NaN",
              )
            }`,
          )
          .integer(
            `${t("recruitment:ProfileHowManyYearsOfExperience")}, ${
              t(
                "recruitment:OnlyIntegers",
              )
            }`,
          )
          .when("haveExperience", {
            is: true,
            then: (schema) =>
              schema.required(
                `${t("recruitment:ProfileHowManyYearsOfExperience")}, ${
                  t(
                    "recruitment:isRequired",
                  )
                }`,
              ),
          })
          .min(1, (context) => {
            return `${t("recruitment:ProfileHowManyYearsOfExperience")}, ${
              t(
                "recruitment:GreaterToEqual",
              )
            } ${context.min}`;
          }),
      }),
      education: profileEducationShape,
      languages: profileLanguagesShape,
      skills: profileSkillShape,
    }),
  });

  return { jobPositionSchema };
};
