import { useState } from "react";
import { Sidenav, Nav } from "rsuite";
import { Box } from "@mui/material";
import { StaffRequest } from "modules/Recruitment/StaffRequests/StaffRequest";
import { useTranslation } from "react-i18next";
import { VacationRequest } from "./VacationRequest";
import { TypesOfPermits } from "./TypesOfPermits";
import { useDispatch } from "react-redux";
import { setJobDescription } from "@redux/slices/jobDescription";
import { JobDescription } from "modules/Organization/JobDescription/JobDescription";

import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";

export const CreateRequests = ({ setOpen }) => {
  const { t } = useTranslation(["request"]);

  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(true);
  const [activeKey, setActiveKey] = useState("1");

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: expanded ? "260px auto" : "56px auto",
          gap: "1rem",
        }}
      >
        <div>
          <Box style={{ width: expanded ? "260px" : "56px" }}>
            <Sidenav expanded={expanded} defaultOpenKeys={[]}>
              <Sidenav.Body>
                <Nav activeKey={activeKey} onSelect={setActiveKey}>
                  <Nav.Item eventKey="1" icon={<GroupIcon />}>
                    {t("request:Solicituddepersonal")}
                  </Nav.Item>
                  <Nav.Item eventKey="2" icon={<DashboardIcon />}>
                    {t("request:Solicituddevacaciones")}
                  </Nav.Item>
                  <Nav.Item eventKey="3" icon={<DashboardIcon />}>
                    {t("request:Solicituddepermisos")}
                  </Nav.Item>
                  <Nav.Item
                    eventKey="4"
                    icon={<DashboardIcon />}
                    onClick={() => {
                      dispatch(
                        setJobDescription({
                          title: "",
                          workMode: "",
                          quantity: 1,
                          department: "",
                          reportAt: null,
                          profile: {
                            gender: "",
                            maritalStatus: "",
                            minAge: "",
                            maxAge: "",
                            experience: {
                              haveExperience: true,
                              howManyYearsOfExperience: 1,
                            },
                            trainingIn: [],
                            specializedKnowledge: [],
                            languages: [],
                            education: [],
                            skills: [],
                          },
                          description: {
                            objective: "<p><br></p>",
                            functions: "<p><br></p>",
                            responsibilities: "<p><br></p>",
                            salaryRange: {
                              minSalary: "",
                              maxSalary: "",
                              currency: ""
                            },
                          },
                          externalRelations: "",
                          positionType: "",
                          publicationLanguage: "",
                          staffRequests: [],
                          status: "REVIEW_REQUIRED"
                        })
                      );
                    }}
                  >
                    {t("request:SolicituddeNuevoPuesto")}
                  </Nav.Item>
                </Nav>
              </Sidenav.Body>
              <Sidenav.Toggle onToggle={(expanded) => setExpanded(expanded)} />
            </Sidenav>
          </Box>
        </div>
        <div>
          {activeKey === "1" && (
            <StaffRequest
              btnBack={false}
              btnsActions={true}
              setOpen={setOpen}
              request={true}
            />
          )}
          {activeKey === "2" && <VacationRequest setOpen={setOpen} />}
          {activeKey === "3" && <TypesOfPermits setOpen={setOpen} />}
          {activeKey === "4" && <JobDescription setOpen={setOpen} />}
        </div>
      </Box>
    </>
  );
};
