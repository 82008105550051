import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Paper } from "@mui/material";
import { Filter } from "components/Filter";
import { UIButton } from "components/UIButton";
import SelectingTable from "components/Table/SelectingTable";
import {
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { PersonAddAlt as PersonAddAltIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { useDispatch } from "react-redux";
import {
  profileSelect,
  setEmployees,
  setEmployeeSelected,
  setOpenFilterPage,
} from "@redux/slices/profile";
import { ModalCreateEmployees } from "./ModalCreateEmployees";
import { getEmployee, selectingEmployeeList } from "api/employee";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAccess, useCountries } from "hooks";

export const Profiles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useCountries();

  const { mainColor } = useSelector(parameterizationSelect);
  const { openFilterPage } = useSelector(profileSelect);

  const [blocked, setBlocked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dataEmployees, setDataEmployees] = useState([]);
  const [renderData, setRenderData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateRows, setUpdateRows] = useState(false);
  const [filters, setFilters] = useState({
    hasFilter: false,
    groupsConfiguration: {},
    groups: {},
  });

  const { t } = useTranslation(["employee"]);
  const open = Boolean(anchorEl);
  const headCells = [
    {
      id: "number",
      numeric: false,
      disablePadding: true,
      label: "#",
    },
    {
      id: "thumbnail",
      numeric: false,
      disablePadding: true,
      label: "",
      isAvatar: true,
    },
    {
      id: "fullName",
      numeric: false,
      disablePadding: true,
      label: t("employee:fullName"),
    },
    {
      id: "isActive",
      numeric: false,
      disablePadding: true,
      label: t("employee:isActive"),
    },
    {
      id: "department",
      numeric: false,
      disablePadding: true,
      label: t("employee:department"),
    },
    {
      id: "supervisor",
      numeric: false,
      disablePadding: true,
      label: t("employee:supervisor"),
    },
    {
      id: "position",
      numeric: false,
      disablePadding: true,
      label: t("employee:position"),
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actionOnClick = async (row) => {
    try {
      setBlocked(true);
      const { data } = await getEmployee("id", row.id);
      dispatch(setEmployeeSelected(data));
      navigate(
        "/cereza-organization/employees/profile/collaborator/personal-information"
      );
    } catch (err) {
      setBlocked(false);
      console.error("Error submitting form:", err);
    }
  };

  const { HasPermissions } = useAccess();
  const { canAdd, canChange } = HasPermissions(
    "/cereza-organization/employees/profiles"
  );

  useEffect(() => {
    const lista = async () => {
      try {
        const { data } = await selectingEmployeeList();
        const rows = data.map((item) => ({
          id: item._id,
          number: item.number,
          thumbnail: item.thumbnail,
          fullName: item.fullName,
          isActive:
            item.isActive === true ? t("employee:Si") : t("employee:No"),
          department: item.department === "" ? "NA" : item.department,
          supervisor: item.supervisor,
          position: item.position,
        }));

        setDataEmployees(rows);
        setFilters({
          hasFilter: false,
          groupsConfiguration: {
            isActive: {
              title: "Activo",
              options: [t("employee:Si"), t("employee:No")],
              type: "checkbox",
            },
            department: {
              title: "Departamento",
              options: [...new Set(rows.map((item) => item.department))],
              type: "checkbox",
            },
          },
          groups: {
            isActive: new Set(),
            department: new Set(),
          },
        });

        const employees = data
          .filter((item) => item.isActive === true)
          .map((item) => {
            if (item.isActive === true) {
              return { value: item._id, label: item.fullName };
            }
          });

        dispatch(setEmployees(employees));
      } catch (error) {
        console.error(error);
      }
    };

    lista();
  }, [updateRows]);

  return (
    <>
      <Box
        sx={
          openFilterPage
            ? {
                display: "grid",
                gridTemplateColumns: "4fr 1fr",
                gap: "3rem",
                "@media (max-width: 600px)": {
                  gridTemplateColumns: "calc(100vw - 48px)",
                },
              }
            : {}
        }
      >
        {dataEmployees.length > 0 ? (
          <SelectingTable
            headCells={headCells}
            rows={renderData.length === 0 ? dataEmployees : renderData}
            selected={selected}
            setSelected={setSelected}
            name={t("employee:Employees")}
            actionOnClick={actionOnClick}
            sxTableTitle={{ flex: "1 1 60%" }}
            blocked={blocked}
          >
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              {canAdd && (
                <Grid item={true} xs={8} md={3}>
                  <UIButton onClick={() => setOpenModal(true)}>
                    <PersonAddAltIcon />
                  </UIButton>
                </Grid>
              )}
              <Grid item={true} xs={8} md={3} sx={{ display: "none" }}>
                <Button
                  id="basic-button"
                  endIcon={<KeyboardArrowDownIcon />}
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  variant="contained"
                  sx={{
                    width: "100%",
                    backgroundColor: mainColor,
                  }}
                >
                  {t("employee:AccionesGrupales")}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuList>
                    <MenuItem onClick={handleClose}>
                      {t("employee:AsignarCompensación")}
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      {t("employee:OtorgarBeneficio")}
                    </MenuItem>
                    <MenuItem onClick={handleClose}>Acción 3</MenuItem>
                    <MenuItem onClick={handleClose}>Otra Acción</MenuItem>
                  </MenuList>
                </Menu>
              </Grid>
              <Grid item={true} xs={12} md={2}>
                <Tooltip title="Filter">
                  <IconButton
                    onClick={() => {
                      dispatch(setOpenFilterPage(!openFilterPage));
                    }}
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </SelectingTable>
        ) : (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {openFilterPage && (
          <Paper>
            <Filter
              title={"Filtro de empleados"}
              filters={filters}
              setFilters={setFilters}
              renderData={dataEmployees}
              setRenderData={setRenderData}
            />
          </Paper>
        )}
      </Box>
      <ModalCreateEmployees
        open={openModal}
        setOpen={setOpenModal}
        setUpdateRows={setUpdateRows}
        canAdd={canAdd}
        canChange={canChange}
      />
    </>
  );
};
