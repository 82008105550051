import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { UIModal, UIButton } from "components";
import { useTranslation } from "react-i18next";
import { getFiles } from "api";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentGeolocation } from "@redux/slices/ui";
import Map from "components/Map";
import FileOpenIcon from "@mui/icons-material/FileOpen";

export const TrakingModal = ({ open, setOpen, registro }) => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);

  const dispatch = useDispatch();

  const { tenant } = useSelector(tenantSettingsSelect);

  const [foto, setFoto] = useState("");
  const [_, setAddress] = useState(null);

  const MyListItemText = (title, text, icon) => {
    return (
      <ListItemText
        primary={
          <>
            {title}
            {icon && (
              <IconButton aria-label="delete" size="small">
                <FileOpenIcon fontSize="inherit" />
              </IconButton>
            )}
          </>
        }
        secondary={<>{text}</>}
      />
    );
  };

  useEffect(() => {
    const fetchSource = async () => {
      try {
        const { data } = await getFiles(tenant?._id, registro?.photo);
        setFoto(data.content);
      } catch (err) {
        console.error("Error getting photo: ", err);
      }
    };

    if (registro?.photo) {
      fetchSource();
    }

    if (registro?.geoLocation) {
      dispatch(
        setCurrentGeolocation({
          lat: registro?.geoLocation[1],
          lng: registro?.geoLocation[0],
        })
      );
    }
  }, [registro]);

  return (
    <UIModal open={open} setOpen={setOpen} width={"50vw"}>
      <Typography variant="h5">
        {t("shiftAttendance:DetalleDeSeguimiento")}
      </Typography>
      <br />

      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {foto && (
          <>
            <ListItem alignItems="flex-start">
              <img
                src={foto}
                alt="foto employee"
                style={{ margin: "0px auto", width: "256px" }}
              />
            </ListItem>
            <Divider />
          </>
        )}

        <ListItem alignItems="flex-start">
          <Grid container spacing={2}>
            <Grid item={true} xs={12} md={4}>
              {MyListItemText(
                t("shiftAttendance:Empleado"),
                registro?.employee?.fullName
              )}
            </Grid>
            <Grid item={true} xs={12} md={4}>
              {MyListItemText(
                t("shiftAttendance:Departamento"),
                registro?.employee?.department
              )}
            </Grid>
            <Grid item={true} xs={12} md={4}>
              {MyListItemText(
                t("shiftAttendance:Tipo"),
                t(`shiftAttendance:${registro?.type}`)
              )}
            </Grid>
          </Grid>
        </ListItem>

        <Divider />

        <ListItem alignItems="flex-start">
          <Grid container spacing={2}>
            <Grid item={true} xs={12} md={4}>
              {MyListItemText(t("shiftAttendance:Fecha"), registro?.registerAt)}
            </Grid>
            <Grid item={true} xs={12} md={4}>
              {MyListItemText(
                t("shiftAttendance:Horario"),
                registro?.schedule?.name
              )}
            </Grid>
            <Grid item={true} xs={12} md={4}>
              {MyListItemText(
                t("shiftAttendance:Reloj"),
                registro?.timeClock?.name
              )}
            </Grid>
          </Grid>
        </ListItem>
        {registro?.geoLocation?.length > 0 && (
          <>
            <Divider />

            <ListItem alignItems="flex-start">
              <Grid container spacing={2}>
                <Grid item={true} xs={12}>
                  <Map setAddress={setAddress} onlyMap={true} />
                </Grid>
              </Grid>
            </ListItem>
          </>
        )}
      </List>

      <br />
      <Divider />
      <br />
      <Box mt={2} width="100%" display="flex" justifyContent="space-between">
        <Box display="flex" gap="1em">
          <UIButton
            label={t("general:Cancel")}
            onClick={() => setOpen(false)}
          />
        </Box>
      </Box>
    </UIModal>
  );
};
