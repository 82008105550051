import { SignOut } from "@keycloak/logout";
import {
  Box,
  Typography,
  Divider,
  ListItemIcon,
  ListItemText,
  Stack,
  Avatar,
  MenuItem,
  MenuList,
  Popover,
  Switch,
} from "@mui/material";
import { Language } from "./Language";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { uiSelect } from "@redux/slices/ui";
import { setUserPreferences } from "@redux/slices/ui";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { setMode } from "@redux/slices/parameterization";
import { updateUserPreferences } from "api";
import Swal from "sweetalert2";
import avatar from "assets/illustrations/avatar.jpeg";
import LockResetIcon from "@mui/icons-material/LockReset";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import NightlightIcon from "@mui/icons-material/Nightlight";

export const MenuAvatar = ({
  openAvatar,
  anchorEl,
  handleClose,
  user,
  handleUpdatePassword,
}) => {
  const { t } = useTranslation(["sidenav"]);
  const dispatch = useDispatch();

  const { keycloakProfile, permissions } = useSelector(authUserSelect);
  const { preferences } = useSelector(uiSelect);
  const { mode } = useSelector(parameterizationSelect);

  const handleChange = async (event) => {
    dispatch(setMode(event.target.checked ? "dark" : "light"));

    const newPreferences = { ...preferences, darkMode: event.target.checked };
    dispatch(setUserPreferences(newPreferences));

    try {
      await updateUserPreferences(user.userId, newPreferences);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Popover
        open={openAvatar}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        sx={{
          mt: 2,
        }}
      >
        <Stack
          spacing={2}
          sx={{
            margin: "15px",
          }}
        >
          <Stack justifyContent="center" alignItems="center">
            <Avatar
              alt="Remy Sharp"
              src={user?.thumbnail ?? avatar}
              sx={{ width: 124, height: 124 }}
            />
            <Box mt={2} />
            <Typography>
              {user?.names} {user?.surnames}
            </Typography>
            <Typography>{user?.email}</Typography>
          </Stack>
          <Divider />
          <MenuList sx={{ width: 320, maxWidth: "100%" }}>
            <MenuItem
              onClick={() => {
                Swal.fire({
                  title: t("sidenav:Are"),
                  text: t("sidenav:Youwon"),
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: t("sidenav:Continuar"),
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleUpdatePassword();
                  }
                });
              }}
            >
              <ListItemIcon>
                <LockResetIcon />
              </ListItemIcon>
              <ListItemText>{t("CambiarContraseña")}</ListItemText>
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <NightlightIcon />
              </ListItemIcon>
              <ListItemText>{t("home:DarkMode")}</ListItemText>
              <Box>
                <Switch
                  checked={mode === "light" ? false : true}
                  onChange={handleChange}
                />
              </Box>
            </MenuItem>
            {(keycloakProfile?.is_superuser ||
              permissions?.apps?.employees === true) && (
              <MenuItem
                onClick={() => {
                  window.location.href = "/home/indicators";
                }}
              >
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText>{t("CerezaEmployee")}</ListItemText>
              </MenuItem>
            )}
            <Language />
            <Divider />
            <MenuItem
              onClick={() => {
                SignOut();
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>{t("CerrarSesion")}</ListItemText>
            </MenuItem>
          </MenuList>
        </Stack>
      </Popover>
    </>
  );
};
