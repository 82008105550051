import { useEffect, useState } from "react";
import { MenuRequests } from "../MenuRequests";
import { Sidenav, Nav } from "rsuite";
import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";
import { Box, TableCell, TableHead, TableRow } from "@mui/material";
import { BasicTable } from "components";
import { getHistoryStaff } from "api";
import { useTranslation } from "react-i18next";
import { useRequests } from "hooks";

export const RequestsHistory = () => {
  const { t } = useTranslation(["request"]);

  const { employees, vacancyReasons, jobsProfile } = useRequests();

  const [expanded, setExpanded] = useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    const fetchRequest = async () => {
      const { data } = await getHistoryStaff();

      const valuesArray = Object.values(data).map((e) => ({
        id: e._id,
        folio: e.folio,
        applicant: employees[e.applicant],
        isNewJob: e.isNewJob === true ? t("general:Si") : t("general:No"),
        vacancyReason: vacancyReasons[e.vacancyReason],
        positionType: t(`recruitment:${e.positionType}`),
        vacancies: e.vacancies,
        jobProfile: jobsProfile[e.jobProfile],
        status: t(`recruitment:${e.status}`),
      }));
      setDataTable(valuesArray);
    };

    if (employees && vacancyReasons && jobsProfile) {
      fetchRequest();
    }
  }, [employees]);

  return (
    <>
      <MenuRequests ubication={"CreateRequests"} />
      <br />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: expanded ? "260px auto" : "56px auto",
          gap: "1rem",
        }}
      >
        <div>
          <Box style={{ width: expanded ? "260px" : "56px" }}>
            <Sidenav expanded={expanded} defaultOpenKeys={["3", "4"]}>
              <Sidenav.Body>
                <Nav activeKey={activeKey} onSelect={setActiveKey}>
                  <Nav.Item eventKey="1" icon={<GroupIcon />}>
                    {t("recruitment:Solicituddepersonal")}
                  </Nav.Item>
                  <Nav.Item eventKey="2" icon={<DashboardIcon />}>
                    Solicitud de vacaciones
                  </Nav.Item>
                </Nav>
              </Sidenav.Body>
              <Sidenav.Toggle onToggle={(expanded) => setExpanded(expanded)} />
            </Sidenav>
          </Box>
        </div>
        <div>
          <BasicTable
            rows={dataTable}
            handleClick={() => {}}
            customStyle={"StatusRequest"}
          >
            <TableCell>{t("recruitment:Folio")}</TableCell>
            <TableCell>{t("recruitment:Applicant")}</TableCell>
            <TableCell>{t("recruitment:IsNewJob")}</TableCell>
            <TableCell>{t("recruitment:VacancyReason")}</TableCell>
            <TableCell>{t("recruitment:PositionType")}</TableCell>
            <TableCell>{t("recruitment:Vacancies")}</TableCell>
            <TableCell>{t("recruitment:JobProfile")}</TableCell>
            <TableCell>{t("recruitment:status")}</TableCell>
          </BasicTable>
        </div>
      </Box>
    </>
  );
};
