import { policesSelect, setAllPolices } from "@redux/slices/attendance-absence";
import { useDispatch, useSelector } from "react-redux";
import { getPolicy } from "api";

export function usePolices() {
  const dispatcher = useDispatch();
  const { allPolices } = useSelector(policesSelect);

  const refreshPolices = async (refresh = false) => {
    if (
      allPolices?.length === 0 ||
      !allPolices ||
      allPolices === undefined ||
      refresh
    ) {
      try {
        const { data } = await getPolicy(null);
        dispatcher(setAllPolices(data));
      } catch (err) {
        console.error("Error getting departments:", err);
      }
    }
  };

  return { refreshPolices };
}
