import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Description = ({ job }) => {
  const { t } = useTranslation(["recruitment"]["general"]);

  return (
    <>
      <Typography variant="subtitle2" gutterBottom mt={2}>
        {`${t("recruitment:VacanciesRequestText1")} ${job?.quantity ?? 0}`}
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        {`${t("recruitment:VacanciesRequestText2")} ${job?.occupiedPositions ?? 0}`}
      </Typography>
      <Typography variant="subtitle2" gutterBottom mb={2}>
        {`${t("recruitment:VacanciesRequestText3")} ${job?.disponibles ?? 0}`}
      </Typography>
    </>
  );
};
